import { useEffect } from 'react';
import cn from 'classnames';
import { LogoProduct } from '@dealroadshow/uikit';
import { useRouter } from 'next/router';

import { Link } from '@/ui/shared/components/Next/Link';
import ContentWrp from '@/ui/shared/components/Layout/ContentWrp';
import Button from '@/ui/shared/components/Button';
import ScrollBodyBackground from '@/ui/shared/components/Layout/ScrollBodyBackground';
import CookiesBanner from '@/ui/shared/components/Landing/CookiesBanner';
import Favicon from './Favicon';
import Header from '@/ui/shared/components/Landing/Sections/Header';
import SectionFooter from '@/ui/shared/components/Landing/Sections/Footer';

import URL from '@/evercall/Router/urlConstants';
import { useTenantContext } from '@/Framework/Tenant/TenantContext';
import {
  EVERCALL_LANDING_HEADER_MENU,
  EVERCALL_LANDING_FOOTER_CONFIG,
} from './constants';

import stickyFooterStyles from '@/ui/shared/components/Landing/StickyFooter/stickyFooter.scss';
import styles from './layout.scss';

interface IProps {
  children: React.ReactNode,
  containerCls?: string,
}

const LandingLayout = ({ children, containerCls }: IProps) => {
  const { tenant } = useTenantContext();
  const { asPath } = useRouter();

  useEffect(() => {
    const id = asPath.split('#')[1];

    if (id) {
      document.getElementById(id)?.scrollIntoView();
    }
  }, []);

  const pageContainerCls = cn(
    styles.landingPageContainer,
    stickyFooterStyles.pageContainerWithStickyFooter,
    containerCls,
  );

  return (
    <ContentWrp>
      <Favicon />
      <Header
        logoProduct={ LogoProduct.EVERCALL }
        menu={ EVERCALL_LANDING_HEADER_MENU }
        headerButton={ ({ buttonProps }) => (
          <Link to={ URL.SCHEDULE }>
            <Button
              { ...buttonProps }
              title="Schedule Your Event"
              dataTest="scheduleEventButton"
            />
          </Link>
        ) }
        mobileMenuClassName={ styles.mobileMenu }
      />
      <div className={ pageContainerCls }>
        <ScrollBodyBackground tenant={ tenant } />
        { children }
      </div>
      <SectionFooter
        config={ EVERCALL_LANDING_FOOTER_CONFIG }
        contentClassName={ styles.footerContent }
      />
      <CookiesBanner />
    </ContentWrp>
  );
};

export default LandingLayout;
